import React from 'react';

/* eslint jsx-a11y/label-has-associated-control: "off" */

const MailForm = () => {
	return (
		<main>
			<div>
				<div className="contact__MainWrapper-sc-1q3ivwk-0 jrsYbQ">
					<section className="contact py-5">
						<div className="container py-5">
							<div className="row pb-5 mb-5">
								<div className="col-12">
									<h1 style={{ width: '100%' }} className="u-color-secondary u-weight-smb">
										Get in touch
									</h1>
								</div>
								<div className="col-12 col-md-8">
									<form name="contact" className="contact__form" action="#" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
										<input type="hidden" name="bot-field" />
  										<input type="hidden" name="form-name" value="contact" />										
										<div className="row mt-5 mb-4">
											<div className="col-12 col-md-6">
												<input
													type="text"
													id="fullname"
													name="fullname"
													placeholder="Your Name"
													required=""
												/>
											</div>
											<div className="col-12 col-md-6">
												<input
													type="email"
													id="email"
													name="email"
													placeholder="Your Email"
													required=""
												/>
											</div>
											<div className="col-12 col-md-6">
												<input
													type="tel"
													id="phone"
													name="phone"
													placeholder="Your Phone"
													required=""
												/>
											</div>
											<div className="col-12 col-md-6">
												<input type="text" name="website" placeholder="Your Website" />
											</div>
											<div className="col-12 mb-4 pb-2">
												<p className="u-weight-bld mb-4">Reason To Contact</p>
												<div className="row">
													<div className="col-4">
														<div className="contact__checkbox">
															<input type="checkbox" id="general-query" name="genquery" />
															<label for="general-query">General Query</label>
														</div>
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="pay-per-click"
																name="payperclick"
															/>
															<label for="pay-per-click">Website Development</label>
														</div>
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="web-development"
																name="webdevelopment"
															/>
															<label for="web-development">Application Development</label>
														</div>
													</div>
													<div className="col-4">
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="free-audit-request"
																name="freeauditrequest"
															/>
															<label for="free-audit-request"> Back Office Development </label>
														</div>
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="social-media-marketing"
																name="social_media_marketing"
															/>
															<label for="social-media-marketing"> Process Automation </label>
														</div>
														<div className="contact__checkbox">
                                                            <input 
                                                                type="checkbox" 
                                                                id="reporting" 
                                                                name="reporting" />
															<label for="reporting">Reporting</label>
														</div>
													</div>
													<div className="col-4">
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="search-engine-optimization"
																name="search_engine_optimizatio"
															/>
															<label for="search-engine-optimization">
																Search Engine Optimization
															</label>
														</div>
														<div className="contact__checkbox">
															<input
																type="checkbox"
																id="email-marketing"
																name="email_marketing"
															/>
															<label for="email-marketing">Email Marketing</label>
														</div>
														<div className="contact__checkbox">
                                                            <input 
                                                                type="checkbox" 
                                                                id="web-design" 
                                                                name="web_design" />
															<label for="web-design">Web Design</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<textarea name="message" placeholder="Your Message" />
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<button id="submit-button" type="submit" className="btn">
													SUBMIT
												</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-12 col-md-4 mb-5 mb-md-0 mt-5 mt-md-0">
                                <aside className="contact__aside pb-md-5">
                                    <div className="contact__aside-intro">
                                        <p className="p--xhg u-font-titles">
										With more than 20 years experience and up-to-date skills, I have the
												know-how to get your project done.
												Ready to know how I can help you?</p>
                                    </div>
                                    <h2 className="h2--lg u-weight-bld u-color-black"><a href="tel:9167906001">916-790-6001</a></h2>
                                    <div className="contact__info my-5">
                                        <div className="contact__info-group">
                                            <div className="contact__info-group-main">
                                                <p className="u-weight-bld mb-2">Email</p>
                                                <p className="mb-2"><a href="mailto:dean.witcraft@outlook.com">dean.witcraft[@]outlook.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
							{/*								
								<div
									className="col-12 col-md-4 mb-5 mb-md-0 mt-5 mt-md-0"
									style={{ paddingTop: '2em' }}
								>
									<aside className="contact__aside pb-md-5">
										<div className="contact__aside-intro">
											<p className="p--xhg u-font-titles">
												With more than 20 years experience and up-to-date skills, I have the
												know-how to get your project done.
											</p>
										</div>
										<h2 className="h2--lg u-weight-bld u-color-black">How can I help you?</h2>
									</aside>
								</div>
								 */}
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>
	);
};
export default MailForm;
//<!-- <a href="tel:916xxxxxxx">916-xxx-xxxx</a> -->
