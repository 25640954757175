import React from "react"
//import { Link, graphql } from "gatsby"
import Header from "../components/header"
import MailForm from "../components/mail-form"

import Layout from "../components/layout"

const MailFormPage = ({ data, location }) => {
  const siteTitle = 'witcraft.io'//data.site.siteMetadata.title
 // const posts = data.allMarkdownRemark.edges

  return (    
  <>
  <Header />
  <Layout location={location} title={siteTitle}>
    <MailForm />
  </Layout>
  </>
  )
}

export default MailFormPage
